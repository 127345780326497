<!--
 修改手机号
-->

<template>
  <div>
    <div class="page-bg"></div>
    <div class="modify-box">
      <div>
        <van-form @submit="onSubmit">
          <div class="text">旧手机号</div>
          <van-field
            v-model="oldMobile"
            type="tel"
            class="field"
            name="oldMobile"
            disabled="true"
            placeholder="手机号"
            :border="false"
          />
          <div class="text">新手机号</div>
          <van-field
            v-model="mobile"
            clearable
            type="tel"
            name="mobile"
            class="field"
            placeholder="输入手机号"
            @blur="mobileBlur"
            :border="false"
          />
          <div v-if="mobileHintShow" class="phone-warn"><span>请输入正确的手机号</span></div>

          <div class="verify-box">
            <div>
              <van-field
                center
                clearable
                class="verify"
                placeholder="输入验证码"
                v-model="verify_code"
                @blur="verifyBlur"
                :border="false"
              />
              <div v-if="verifyHint" class="verify-warn">
                <span>{{ verifyHint ? verifyHint : '请输入正确的验证码' }}</span>
              </div>
            </div>
            <van-button
              size="small"
              class="sendverify"
              type="default"
              @click="clickSendSms"
              plain
              hairline
              :disabled="complainSMS.disabled"
              >{{ complainSMS.text }}</van-button
            >
          </div>

          <van-button
            block
            plain
            native-type="submit"
            type="default"
            class="submit-btn"
            :disabled="submitDisabled"
            >提交</van-button
          >
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue';
import { nftUtils } from '../utils';
import { useRouter } from 'vue-router';
import { getUserData } from '@/service/user';
import { useStore } from 'vuex';
import { Toast } from 'vant';
import { sendSms, setMobile } from '@/service/user';
export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      oldMobile: '',
      mobile: '',
      verify_code: '',
      mobileHintShow: false, //显示手机号错误提示
      haveErify: false, //是否填写了验证码
      verifyHint: '', //显示验证码错误提示
      submitDisabled: true,
      complainSMS: {
        text: '获取验证码',
        disabled: true,
        isCountDown: false
      }
    });

    onMounted(async () => {
      if (store.state.loginInfo && store.state.loginInfo.mobile) {
        state.oldMobile = nftUtils.getNoFullMobile(store.state.loginInfo.mobile);
      }
      const { data, status } = await getUserData();
      if (data && status === 0) {
        state.oldMobile = nftUtils.getNoFullMobile(data.mobile);
        if (data.id_verify) {
          router.push({ path: '/user', replace: true });
        }
      }
    });

    const mobileBlur = () => {
      state.mobileHintShow = !nftUtils.mobileTest(state.mobile);
      state.complainSMS.disabled = state.mobileHintShow || state.complainSMS.isCountDown;
      state.submitDisabled = state.mobileHintShow || !state.haveErify;
    };

    const verifyBlur = () => {
      state.haveErify = !!state.verify_code;
      state.verifyHint = !state.haveErify ? '验证码不能为空' : '';
      state.submitDisabled = state.mobileHintShow || !state.haveErify;
    };

    const clickSendSms = () => {
      nftUtils.setcnzzRecordByBtn('点击发送验证码');
      const tel = state.mobile;
      console.log(tel);
      if (!nftUtils.mobileTest(tel)) {
        Toast('请输入正确的手机号码');
        return;
      }
      sendSms({ mobile: state.mobile })
        .then(res => {
          if (res.status === 0) {
            Toast('发送成功~');
            return res.data;
          } else {
            Toast({ message: res.message });
          }
        })
        .catch(err => {
          Toast(err.message || '提交失败，稍后再试。');
        })
        .then(() => {
          nftUtils.countDownFn(state.complainSMS);
        });
    };
    const onSubmit = async () => {
      nftUtils.setcnzzRecordByBtn('提交修改手机号');
      const { data, status, message } = await setMobile({
        mobile: state.mobile,
        code: state.verify_code
      });
      if (status === 0 && data) {
        router.push({ path: '/user', replace: true });
      } else if (message) {
        state.verifyHint = message;
        Toast(message);
      }
    };

    return {
      ...toRefs(state),
      mobileBlur,
      verifyBlur,
      clickSendSms,
      onSubmit
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
.modify-box {
  position: relative;
  width: 100%;
  font-size: 18px;
  padding-top: 40px;
  .text {
    margin-top: 20px;
    margin-left: 20px;
  }
  .field {
    margin-top: 20px;
    width: 335px;
    height: 44px;
    background-color: $primaryc;
    left: 50%;
    transform: translateX(-50%);
    --van-field-input-text-color: #fff;
    --van-field-placeholder-text-color: #6d6979;
  }
  .phone-warn {
    color: $redc;
    font-size: 12px;
    margin-left: 20px;
    margin-top: 10px;
    height: 12px;
  }
  .verify-warn {
    color: $redc;
    font-size: 12px;
    margin-top: 10px;
    height: 12px;
  }
  .verify-box {
    margin-top: 20px;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    --van-field-input-text-color: #fff;
    --van-field-placeholder-text-color: #6d6979;
  }
  .verify {
    width: 171px;
    height: 44px;
    background-color: $primaryc;
  }
  .sendverify {
    margin-left: 12px;
    width: 152px;
    height: 44px;
    font-size: 14px;
    --van-button-plain-background-color: $bc;
    --van-button-default-border-color: $fc;
    --van-button-border-width: 1px;
    --van-button-default-color: $fc;
    --van-button-border-radius: 4px;
  }
}
</style>
